@import "./Mixins.scss";

@font-face {
  font-family: "JioType";
  src: url("/fonts/JioType-Medium.woff2") format("woff2"),
    url("/fonts/JioType-Medium.woff") format("woff"),
    url("/fonts/JioType-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JioType";
  src: url("/fonts/JioType-Bold.woff2") format("woff2"),
    url("/fonts/JioType-Bold.woff") format("woff"),
    url("/fonts/JioType-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JioType";
  src: url("/fonts/JioType-Light.woff2") format("woff2"),
    url("/fonts/JioType-Light.woff") format("woff"),
    url("/fonts/JioType-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JioType";
  src: url("/fonts/JioType-Black.woff2") format("woff2"),
    url("/fonts/JioType-Black.woff") format("woff"),
    url("/fonts/JioType-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
h1,
h2,
h3,
h4,
h5 {
  font-family: "JioType";
}

h1 {
  @include fontSize(48px);
  font-weight: 900;
  line-height: 48px;
  letter-spacing: -2px;
}
h2 {
  @include fontSize(40px);
  font-style: normal;
  font-weight: 900;
  line-height: 40px; /* 100% */
  letter-spacing: -1.2px;
  @media (max-width: 820px) {
    @include fontSize(32px);
    line-height: 32px; /* 100% */
  }
}
h3 {
  @include fontSize(32px);
  font-weight: 900;
  font-style: normal;
  line-height: 34px;
  letter-spacing: -0.96px !important;
}
h4 {
  @include fontSize(24px);
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.3px;
  @media (max-width: 820px) {
    @include fontSize(18px);
    line-height: 24px; /* 100% */
  }
}
h5 {
  @include fontSize(18px);
  font-weight: 500;
  line-height: 24px;
}
h6 {
  @include fontSize(16px);
  font-weight: 500;
  letter-spacing: -0.5px;
}
.para {
  @include fontSize(16px);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: $lightgrey;
}
p {
  @include fontSize(16px);
  font-weight: 400;
  line-height: 24px;
  color: $darkgrey;
}
p {
  small {
    @include fontSize(12px);
    line-height: 16px;
    font-weight: 500;
  }
}
