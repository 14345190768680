@import "../SCSS/FonStyle.scss";
// @import "@glidejs/glide/dist/css/glide.core.min.css";
// @import "@glidejs/glide/dist/css/glide.theme.min.css";

a {
  text-decoration: none !important;
  cursor: pointer;
}

ul li {
  list-style-type: none;
}

.MuiGrid2-container {
  width: 100%;
  max-width: 1190px;
  margin-left: auto;
  margin-right: auto;
}

img {
  width: 100%;
  height: auto;
  vertical-align: top;
}

.homeWrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.pdpWrapper {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media (max-width: 820px) {
    padding-top: 66px;
  }

  .innerWrapper {
    display: flex;
    flex-direction: column;
    gap: 80px;

    @media (max-width: 820px) {
      padding-left: 12px;
      padding-right: 12px;
      gap: 24px;
    }

    h4 {
      margin-top: 24px;
    }

    @media (max-width: 1024px) {
      h2 {
        @include fontSize(32px);
        line-height: 32px;
      }

      h4 {
        @include fontSize(16px);
        line-height: 24px;
        margin-top: 12px;
      }
    }
  }
}

.pdpBanner {
  h1 {
    line-height: 50px;
    margin: 16px 0px;

    @media (max-width: 820px) {
      @include fontSize(36px);
      line-height: 42px;
      letter-spacing: -1.44px;
    }
  }

  .content {
    position: absolute;
    left: 64px;
    top: 50%;
    transform: translate(0, -50%);
    color: $white;
    width: 45%;

    @media (max-width: 1024px) {
      top: auto;
      bottom: 24px;
      transform: translate(0, 0);
      left: 0px;
      padding: 0px 20px;
      width: 100%;
      max-width: 530px;
    }

    @media (max-width: 820px) {
      top: auto;
      bottom: 24px;
      transform: translate(0, 0);
      left: 0px;
      padding: 0px 24px;
      width: 100%;
    }
  }

  .subtitle {
    @include fontSize(48px);
    color: $white;
    font-weight: 900;
    line-height: 48px;
    letter-spacing: -2px;
    font-family: "JioType";
    line-height: 50px;
    opacity: 1;
    margin: 16px 0;

    @media (max-width: 820px) {
      @include fontSize(36px);
      line-height: 37px;
    }
  }

  p {
    color: $white;
    opacity: 0.8;
  }

  &.car {
    .bgImage {
      img {
        object-position: 30% center;
      }
    }
  }

  .bgImage {
    height: 520px;
    overflow: hidden;
    border-radius: 20px;
    display: flex;

    @media (max-width: 1200px) {
      border-radius: 0px;
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .tertiaryBtns {
    .MuiButton-root {
      color: $primary !important;
    }
  }
}

.whyInsureList {
  display: flex;
  flex-direction: column;
  gap: 18px;
  list-style-type: none;

  li {
    display: flex;
    gap: 8px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 18px;

    &:last-child {
      border: none;
    }
  }

  .icon {
    background-color: $lightMarigold20;
    border-radius: 40px;
    height: 32px;
    width: 32px;
    padding: 4px;
  }
}

.popularPlans {
  @media (min-width: 1024px) {
    h2 {
      text-align: center;
    }
  }
}

.productCarousel {
  padding-top: 24px;
  padding-bottom: 72px;

  &.banerTablet {
    .react-multiple-carousel__arrow {
      bottom: 24px;
      // z-index: 9;
    }
  }

  @media (max-width: 1024px) {
    padding-top: 0px;
    padding-bottom: 28px;
  }

  .carousel-item-padding-40-px {
    padding: 0px 8px;
  }

  .carousel-item-padding-10-px {
    padding: 0px 10px;
  }

  .react-multi-carousel-dot-list {
    @media (min-width: 1025px) {
      display: none;
    }
  }

  .react-multiple-carousel__arrow {
    background: $lightMarigold25;
    opacity: 1;
    bottom: 0px;
    min-width: 40px;
    min-height: 40px;
    height: 40px;
    width: 40px;

    &:hover {
      background: $lightMarigold20;
    }

    &:before {
      position: absolute;
      background: $darkgrey;
      border-radius: 4px;
      content: "";
      height: 8px;
      width: 2.5px;
    }

    &:after {
      @extend :before;
    }

    &.react-multiple-carousel__arrow--left {
      left: auto;
      right: 72px;

      &:before {
        transform: rotate(45deg);
        top: 14px;
        left: 18px;
      }

      &:after {
        transform: rotate(-45deg);
        left: 18px;
        top: 18px;
      }
    }

    &.react-multiple-carousel__arrow--right {
      right: 16px;

      &:before {
        transform: rotate(-45deg);
        top: 14px;
        left: 19px;
      }

      &:after {
        transform: rotate(45deg);
        left: 19px;
        top: 18px;
      }
    }
  }
}

.addons {
  h2,
  h4 {
    text-align: center;

    @media (max-width: 820px) {
      text-align: left;
      padding: 0px 24px;
    }
  }

  .addonCard {
    h4 {
      padding-left: 0px;
    }
  }
}

.testimonialCard {
  border: 1px solid $lightbg;
  padding: 24px;
  border-radius: 24px;
  height: 100%;

  @media (max-width: 767px) {
    padding: 16px;
  }

  // h5 {
  //   margin-bottom: 32px;
  //   @media (max-width: 767px) {
  //     margin-bottom: 24px;
  //   }
  // }
  h6 {
    @include fontSize(18px);

    @media (max-width: 767px) {
      @include fontSize(16px);
    }
  }
}

.react-multi-carousel-dot-list {
  .react-multi-carousel-dot--active button {
    min-width: 32px;
    opacity: 1 !important;
  }

  .slick-dots li {
    margin: 0px 4px;
    width: 12px;
    height: 12px;

    &.slick-active {
      min-width: 32px;
      width: 32px;
    }
  }

  .react-multi-carousel-dot {
    transition: all 0.3s ease-in-out;
  }

  .react-multi-carousel-dot button,
  li button {
    background: #9b6817 !important;
    border-radius: 50px !important;
    opacity: 0.3;
    border: none;
    width: 12px;
    height: 12px;

    &::before {
      display: none;
    }
  }
}

// .glide {
//   .glide__arrows {
//     height: 40px;
//     margin-top: 24px;
//     .glide__arrow {
//       background: $lightMarigold20;
//       border-radius: 40px;
//       box-shadow: none;
//       border: none;
//       height: 40px;
//       width: 40px;
//       min-height: 40px;
//       min-width: 40px;
//       &:before {
//         position: absolute;
//         background: $darkgrey;
//         border-radius: 4px;
//         content: "";
//         height: 8px;
//         width: 2.5px;
//       }
//       &:after {
//         @extend :before;
//       }
//       &.glide__arrow--left {
//         left: auto;
//         right: 172px;
//         &:before {
//           transform: rotate(45deg);
//           top: 14px;
//           left: 18px;
//         }
//         &:after {
//           transform: rotate(-45deg);
//           left: 18px;
//           top: 18px;
//         }
//       }
//       &.glide__arrow--right {
//         right: 120px;
//         &:before {
//           transform: rotate(-45deg);
//           top: 14px;
//           left: 19px;
//         }
//         &:after {
//           transform: rotate(45deg);
//           left: 19px;
//           top: 18px;
//         }
//       }
//     }
//   }
// }

.MuiAutocomplete-popper {
  background: $white !important;
}
.MuiMenu-root {
  z-index: 99991 !important;
}
.MuiPopper-root {
  @include border-radius(0px 0px 8px 8px !important);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2) !important;
  z-index: 9991 !important;

  ul {
    max-height: 200px !important;
    @include border-radius(0px 0px 8px 8px !important);
    overflow-y: auto;
    width: 100% !important;

    .MuiAutocomplete-option {
      background: $white;
      font-size: 14px !important;
      line-height: 18px !important;
      min-height: 32px !important;
      font-family: "JioType" !important;
      font-weight: 500 !important;
      width: 100% !important;
    }
  }
}

// Calendar
.react-datepicker__portal {
  background-color: rgba($black, 0.4) !important;

  @media (max-width: 767px) {
    // align-items: flex-end !important;
  }

  .react-datepicker {
    box-shadow: 0px 4px 12px rgba($black, 0.2);
    border-radius: 24px;
    border: none;
    position: relative;

    @media (max-width: 767px) {
      box-shadow: 0px 0px 12px rgba($black, 0.2);
      border-radius: 0px;
      width: 100%;
      margin-bottom: -48px;
    }

    .react-datepicker__header {
      background-color: $white;
      padding: 12px 12px 2px;
      border: none;
      border-radius: 24px;
      position: static;

      .dropdownSelection {
        position: relative;

        &.active {
          border-color: $primary;
          background: $lightMarigold20;

          &:before {
            transform: rotate(135deg);
            border-color: $primary;
            top: 22px;
          }
        }

        &.year {
          &.active {
            &:before {
              top: 20px;
            }
          }

          &:before {
            top: 16px;
          }
        }

        &:before {
          content: "";
          position: absolute;
          right: 20px;
          top: 18px;
          height: 6px;
          width: 6px;
          border-color: #67440c;
          border-width: 0 0 2px 2px;
          border-style: solid;
          transition: all ease-in-out 0.3s;
          transform: rotate(-45deg);
          z-index: 9;
        }

        border: 1px solid $lightbg;
        height: 46px;
        background: $white;
        border-radius: 100px;
        padding: 12px 40px 8px 16px;
        line-height: 24px;
        @include fontSize(16px);
        font-weight: bold;
        color: #67440c;
        text-transform: capitalize;
        min-width: 96px;
      }

      .menuList {
        position: absolute;
        top: 124px;
        left: 0px;
        background: $white;
        border-top: 1px solid $lightbg;
        border-bottom: 1px solid $lightbg;
        padding: 12px;
        z-index: 10;
        width: 100%;
        max-height: 363px;
        min-height: 363px;
        overflow-y: auto;

        @media (max-width: 767px) {
          max-height: 347px;
          min-height: 347px;
        }
      }

      .react-datepicker__day-names {
        font-weight: bold;
        font-family: "JioType";
        @include fontSize(16px);
        padding: 12px 8px;
        margin: 0px;
        display: flex;
        justify-content: space-between;

        .react-datepicker__day-name {
          color: $lightgrey;
          margin: 0px;
          font-family: "JioType";
        }
      }
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__month {
      margin: 0px 0px 120px;
      padding: 0px 24px 12px;
      border-bottom: 1px solid $lightbg;
      min-height: 308px;

      @media (max-width: 767px) {
        padding: 0px 12px 12px;
      }
    }
  }

  .react-datepicker__day,
  .react-datepicker__day:not([aria-disabled="true"]) {
    &:hover {
      border-radius: 100px;
    }

    margin: 0px;
    color: #67440c;
    font-weight: bold;
    font-family: "JioType";
    @include fontSize(16px);
    line-height: 46px;
    width: 48px;
    height: 48px;

    &.react-datepicker__day--keyboard-selected:not([aria-disabled="true"]) {
      background-color: rgba($primary, 0.4);
      border-radius: 100px;

      &:hover {
        background-color: rgba($primary, 0.4);
        border-radius: 100px;
      }
    }

    &.react-datepicker__day--selected {
      border-radius: 100px;
      background: $primary;
      color: $white;

      &:hover {
        background-color: $primary;
        border-radius: 100px;
      }
    }

    &.react-datepicker__day--today {
      border-radius: 100px;
      background: $white !important;
      color: #674419 !important;

      &:hover {
        background-color: rgba($primary, 0.4);
        border-radius: 100px;
        color: $white;
      }

      &.react-datepicker__day--selected {
        border-radius: 100px;
        background: $primary !important;
        color: $white !important;
      }
    }

    &.react-datepicker__day--outside-month {
      opacity: 0.5;
    }
  }

  .react-datepicker__day:not([aria-disabled="false"]) {
    margin: 0px;
    color: #ccc;
    width: 48px;
    height: 48px;
  }
}

.react-datepicker__input-container {
  position: relative;

  input {
    background: transparent;
    height: 40px;
    border: none;
    cursor: pointer;
    padding: 8px 0px;
    @include fontSize(16px);
    font-family: "JioType";
    width: 100%;
    position: relative;
    z-index: 2;

    &:focus-visible {
      outline: none;
    }
  }

  &:before {
    content: "";
    background: $lightgrey;
    border-radius: 4px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 2px;
    width: calc(100% - 52px);
    z-index: 1;
  }

  &:after {
    content: "";
    background: url("/images/ic_calendar.svg") no-repeat right 10px center/22px;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 38px;
    width: 38px;
    z-index: 1;
  }
}
